// extracted by mini-css-extract-plugin
export var automotiveBenefitsSection = "V_jd";
export var automotiveCasesSection = "V_jj";
export var automotiveClientQuotesSection = "V_jn";
export var automotiveDevExpertiseSection = "V_jl";
export var automotiveIndustriesSection = "V_jc";
export var automotiveProcessSection = "V_jf";
export var automotiveRequirementsSection = "V_jh";
export var automotiveServicesSection = "V_jb";
export var automotiveSkilledSection = "V_jm";
export var automotiveTechStackSection = "V_jg";
export var automotiveWhyHireSection = "V_jk";